import SmoothScroll from 'smooth-scroll';
window.SmoothScroll = SmoothScroll;

// Show more text on clicking 'weiterlesen' (mobile only)
document.addEventListener("DOMContentLoaded", function(event) {
  const readMoreLink = document.getElementById("read-more");
  const readMoreText = document.getElementById("read-more-text");
  const vieportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const breakpointWidth = 650;
  if (readMoreLink && readMoreText && vieportWidth < breakpointWidth) {
    readMoreLink.textContent = "weiterlesen\xa0…";
    readMoreText.classList.add('hidden');
    readMoreLink.addEventListener('click', function(){
      readMoreText.classList.remove('hidden');
      readMoreLink.remove();
    }, false);
  }
})
